import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";
import React, { useState, useEffect, useRef } from "react";
import { Platform, Text, View, StyleSheet } from "react-native";

import ActividadesStackNavigator from "./ActividadesStackNavigator";
import PerfilStackNavigator from "./PerfilStackNavigator";
import { AuthenticatedDrawerParamList } from "./Routes";
import { ConectaIdeasProvider } from "../components/ConectaIdeas";
import { getApolloClient } from "../components/ConectaIdeas/ConectaIdeasClient";
import { getPlatform } from "../components/functions/authFunctions";
import { getKeyFromAsyncStorage } from "../components/functions/storageFunctions";
import { isDEV, isBETA } from "../constants/dev";
import parameters from "../metadata.json";
import ExitScreen from "../screens/ExitScreen";
import SecretScreen from "../screens/SecretScreen";
import TestScreen from "../screens/TestScreen";

const Drawer = createDrawerNavigator<AuthenticatedDrawerParamList>();

function CustomDrawerContent(props) {
  const version = `${getPlatform(Platform.OS)} v${parameters.version}`;
  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.container}>
        <Text style={styles.text}>{props.fullname}</Text>
      </View>
      <DrawerItemList {...props} />
      <DrawerItem label={version} onPress={props.onClick} />
    </DrawerContentScrollView>
  );
}

/**
 * This navigator is only enabled after a user has been successfully authenticated
 */

const DEFAULT_USER = { nombre: "", fullname: "" };

export default function AuthenticatedNavigator() {
  const [user, setUser] = useState(DEFAULT_USER);
  const [showSecretScreen, setShowShowSecretScreen] = useState(false);
  const clicks = useRef(0);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  useEffect(() => {
    getApolloClient().then((c) => {
      setClient(c);
    });
    getKeyFromAsyncStorage("userAttributes", null).then((userAtts) => {
      if (userAtts) {
        setUser({
          fullname: userAtts.name + " " + userAtts.family_name,
          nombre: userAtts.name,
        });
      }
    });
  }, []);

  const onClick = () => {
    clicks.current = clicks.current + 1;
    if (clicks.current === 7) {
      setShowShowSecretScreen(true);
    }
  };

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ConectaIdeasProvider>
        <Drawer.Navigator
          screenOptions={{ headerShown: false }}
          drawerContent={(props) => (
            <CustomDrawerContent
              {...props}
              fullname={user.fullname}
              onClick={onClick}
            />
          )}
        >
          <Drawer.Screen
            name="Actividades"
            component={ActividadesStackNavigator}
            options={{ title: "ConectaIdeas" }}
          />

          {isDEV && (
            <Drawer.Screen
              name="PerfilRecovery"
              component={PerfilStackNavigator}
              options={{ title: "Mi perfil" }}
            />
          )}
          {/* XXX: Preguntas metacognitivas temporalmente desactivadas, no son usadas por Perú */}
          {/*<Drawer.Screen*/}
          {/*  name="Metacognitivas"*/}
          {/*  options={{ title: "Preguntas metacognitivas" }}*/}
          {/*>*/}
          {/*  {() => <PreguntasStackNavigator nombre={user.nombre} />}*/}
          {/*</Drawer.Screen>*/}

          {(isDEV || (isBETA && user.fullname === "Alumno N°1")) && (
            <Drawer.Screen name="Test" component={TestScreen} />
          )}

          <Drawer.Screen
            name="Exit"
            component={ExitScreen}
            options={{
              title: "Cerrar sesión",
            }}
          />
          {showSecretScreen && (
            <Drawer.Screen
              name="Secret"
              component={SecretScreen}
              options={{
                title: "Información secreta",
              }}
            />
          )}
        </Drawer.Navigator>
      </ConectaIdeasProvider>
    </ApolloProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgb(32,96,234)",
    padding: 12,
  },
  text: {
    color: "white",
    fontWeight: "bold",
  },
});
