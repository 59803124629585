import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { View } from "react-native";

import BackArrow from "./BackArrow";
import ErrorCounter from "./ErrorCounter";
import SkipButton from "./SkipButton";
import Timer from "./Timer";
import { getTopBarStyles } from "./getStyles";
import useIsActiveExercise from "../../hooks/useIsActiveExercise";
import { ScreenInfoType } from "../../hooks/useScreenInfo";
import { ExerciseUserDataType } from "../ExerciseComponents/ExerciseInterfaces";

const ONE_MINUTE_IN_SECS = 60;
const ONE_SECOND_MS = 1000;
const FORMAT_POSITION = 2;
const REPORT_SECOND_MS = 45000;

const formatNumber = (number: number) => `0${number}`.slice(-FORMAT_POSITION);

const getRemaining = (elapsedTimeInSeconds: number) => {
  const mins = Math.floor(elapsedTimeInSeconds / ONE_MINUTE_IN_SECS);
  const secs = elapsedTimeInSeconds - mins * ONE_MINUTE_IN_SECS;
  return { mins: formatNumber(mins), secs: formatNumber(secs) };
};

interface InputTopBar {
  reportProgress: (time: number) => void;
  timeRef: MutableRefObject<number>;
  screenInfo: ScreenInfoType;
  openSkipModal: (open: boolean) => void;
  pais: string;
  info: any;
  userStatus: ExerciseUserDataType;
  goToTareasList: () => void;
}

export default function TopBar({
  reportProgress,
  timeRef,
  screenInfo,
  openSkipModal,
  pais,
  info,
  userStatus,
  goToTareasList,
}: InputTopBar) {
  const showTimer = pais === "Chile";
  const showSkipButton = userStatus.errorCount >= 1 && !userStatus.completed;
  const styles = getTopBarStyles(screenInfo);
  const isActive = useIsActiveExercise();
  const intervalTimeRef = useRef<any>(null);
  const initials = getRemaining(Math.floor(timeRef.current / ONE_SECOND_MS));
  const [{ mins, secs }, setTime] = useState({
    mins: initials.mins,
    secs: initials.secs,
  });

  const lastReportRef = useRef(Date.now());

  useEffect(() => {
    if (isActive && !userStatus.completed) {
      startTimer();
    } else {
      stopTimer();
    }
    return () => {
      if (intervalTimeRef.current) {
        clearInterval(intervalTimeRef.current);
      }
    };
  }, [isActive, userStatus.completed]);

  const startTimer = () => {
    const start = Date.now() - timeRef.current;
    intervalTimeRef.current = setInterval(() => {
      const elapsedTimeInMS = Date.now() - start; //milisegundos
      const aux = getRemaining(Math.floor(elapsedTimeInMS / ONE_SECOND_MS));
      setTime({ mins: aux.mins, secs: aux.secs });
      timeRef.current = elapsedTimeInMS;
      if (showTimer && Date.now() - lastReportRef.current >= REPORT_SECOND_MS) {
        lastReportRef.current = Date.now();
        reportProgress(elapsedTimeInMS);
      }
      if (userStatus.completed) {
        stopTimer();
      }
    }, ONE_SECOND_MS);
  };

  const stopTimer = () => {
    clearInterval(intervalTimeRef.current);
  };

  return (
    <View style={styles.headerBox}>
      <BackArrow goToTareasList={goToTareasList} styles={styles.arrowButton} />
      <View style={styles.rightBox} testID="timer_container">
        {showTimer && (
          <>
            <View style={styles.timerBox}>
              <Timer accumulatedTime={{ mins, secs }} />
            </View>
            <View style={styles.countBox}>
              <ErrorCounter errorNumber={userStatus.errorCount} />
            </View>
          </>
        )}

        <SkipButton
          open={openSkipModal}
          styles={styles}
          show={showSkipButton}
        />
      </View>
      {info}
    </View>
  );
}
