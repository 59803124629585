import "expo-dev-client";
import { Auth } from "@aws-amplify/auth";
import { NavigationContainer } from "@react-navigation/native";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Audio } from "expo-av";
import { InterruptionModeIOS } from "expo-av/src/Audio.types";
import * as Device from "expo-device";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import _ from "lodash";
import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { enableScreens } from "react-native-screens";

import { authConfig } from "./Config";
import { AuthenticationContext } from "./src/components/AuthenticationContext";
import { getCurrentSession } from "./src/components/ConectaIdeas/ConectaIdeasClient";
import { Logger } from "./src/components/Logger";
import { shouldRequestPermissions } from "./src/components/NotificacionesComponents/pushUtils";
import { clearLocalStorage } from "./src/components/Storage";
import { loginReducer } from "./src/components/functions/authFunctions";
import { ENV, isDEV } from "./src/constants/dev";
import { HTTP_SUCCESS } from "./src/constants/numbers";
import ModalVersion, { VersionActions } from "./src/helpers/ModalVersion";
import { isOldChromeVersion } from "./src/helpers/helpersFunctions";
import useConnectionStatus from "./src/hooks/useConnectionStatus";
import { Sentry } from "./src/lib/Sentry";
import parameters from "./src/metadata.json";
import AuthenticatedNavigator from "./src/navigation/AuthenticatedNavigator";
import LinkingConfiguration from "./src/navigation/LinkingConfiguration";
import LoginStackNavigator from "./src/navigation/LoginStackNavigator";
import PermissionsNavigator from "./src/navigation/PermissionsNavigator";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

enableScreens();

const SentryParams = {
  dsn: "https://0ef32e5e0c9e4cf6b58254810207bf6f@o310822.ingest.sentry.io/4504328276738048",
  enableInExpoDevelopment: false,
  release: "conectaIdeasOffline@" + parameters.version,
  environment: ENV,
  debug: isDEV,
  initialScope: {
    tags: {
      marca: Device.brand,
      modelo: Device.modelName,
      version: Device.osName + " " + Device.osVersion,
    },
  },
  tracesSampleRate: isDEV ? 0 : 0.1,
};

Sentry.init(SentryParams);

Auth.configure(authConfig);

type ModalState = {
  show: boolean;
  action?: string;
};

export default function App() {
  const [authenticationState, dispatch] = useReducer(loginReducer, {
    loading: true,
    isAuthenticated: false,
  });

  const [requestPermissions, setRequestPermissions] = useState(false);
  const isOnline = useConnectionStatus();
  const [permitido, setPermitido] = useState(false);
  const [modalVersion, setModalVersion] = useState<ModalState>({
    show: false,
  });

  useEffect(() => {
    SplashScreen.preventAutoHideAsync().then(() => {
      shouldRequestPermissions().then((result) =>
        setRequestPermissions(result)
      );
    });
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      interruptionModeIOS: InterruptionModeIOS.DuckOthers,
    });
  }, []);

  useEffect(() => {
    if (
      Platform.OS === "web" &&
      isOldChromeVersion(window.navigator.userAgent)
    ) {
      Logger.info("Versión obsoleta de chrome:", window.navigator.userAgent);
      setModalVersion({ show: true, action: VersionActions.OBSOLETO });
    } else {
      setPermitido(true);
    }
    if (isOnline && Platform.OS !== "web") {
      fetch("https://www.offlineconectaideas.com/metadata.json")
        .then((response) => {
          if (response.status === HTTP_SUCCESS) {
            return response.json();
          } else {
            throw new Error(
              "Failed to get metadata from https://www.offlineconectaideas.com/metadata.json"
            );
          }
        })
        .then((data) => {
          Logger.info(
            `Versión servidor:${data.version}-${data.branch} -->`,
            `Versión ejecutada:${parameters.version}`
          );
          const lastVersion = _.parseInt(_.split(data.version)[0]);
          const currentVersion = _.parseInt(_.split(parameters.version)[0]);

          if (lastVersion - currentVersion === 1) {
            setModalVersion({ show: true, action: VersionActions.WARN });
          }
          if (lastVersion - currentVersion > 1) {
            setModalVersion({ show: true, action: VersionActions.BLOCK });
          }
        })
        .catch((e) => Logger.error("Error al iniciar", e));
    }
  }, [isOnline]);

  const authContext = {
    signIn: (data: CognitoUserSession) => {
      if (data === undefined) {
        throw new Error("Falta token de autenticacion");
      }
      dispatch({ type: "SIGN_IN" });
    },
    signUp: () => {
      dispatch({ type: "SIGN_UP" });
    },
    signOut: async () => {
      await clearLocalStorage();
      await Auth.signOut();
      dispatch({ type: "SIGN_UP" });
    },
  };

  useEffect(() => {
    if (permitido) {
      getCurrentSession()
        .then((auth) => {
          const username = auth.getAccessToken().payload.username;
          Sentry.setUser({ username });
          Sentry.setTag("platform", Platform.OS);
          authContext.signIn(auth);
        })
        .catch(() => {
          authContext.signUp();
        })
        .finally(() => {
          return SplashScreen.hideAsync();
        });
    }
  }, [permitido]);

  if (requestPermissions && Platform.OS === "ios") {
    return (
      <NavigationContainer linking={LinkingConfiguration}>
        <PermissionsNavigator setRequestPermissions={setRequestPermissions} />
      </NavigationContainer>
    );
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <StatusBar style="auto" />
      <AuthenticationContext.Provider value={authContext}>
        <NavigationContainer linking={LinkingConfiguration}>
          {authenticationState.isAuthenticated ? (
            <AuthenticatedNavigator />
          ) : (
            <LoginStackNavigator />
          )}
          <ModalVersion
            close={() => setModalVersion({ show: false })}
            show={modalVersion.show}
            action={modalVersion.action}
          />
        </NavigationContainer>
      </AuthenticationContext.Provider>
    </GestureHandlerRootView>
  );
}

serviceWorkerRegistration.register();
