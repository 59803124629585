import { useApolloClient } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useReducer, useState } from "react";

import {
  TAREA_ID_TEST_MODE,
  reducerExerciseIndex,
} from "./exerciseIndexReducer";
import {
  extractFlags,
  getUserStatusForTareas,
} from "./functionUseExerciseIndex";
import {
  listPendingDownloadExercises,
  listPendingUploadExercises,
} from "./indexFunctions";
import {
  ActionExerciseIndexType,
  ExerciseIndexAction,
} from "./reducerIntefaces";
import useConnectionStatus from "./useConnectionStatus";
import useQueryWithOfflineSupport from "./useQueryWithOfflineSupport";
import {
  ExerciseIndexState,
  PendingUploadCollectionType,
  ExerciseWithStatus,
  GenericUploadType,
} from "../Interfaces";
import ConectaIdeasClient, {
  uploadEvaluation,
} from "../components/ConectaIdeas/ConectaIdeasClient";
import { EXERCISE_INITIAL_STATUS } from "../components/ExerciseComponents/ExerciseReducer";
import { Logger } from "../components/Logger";
import {
  exerciseLocalStorageKey,
  metaAnswerUserInfoStorageKey,
} from "../components/Utils";
import { isDEV } from "../constants/dev";
import { getExerciseKeyById, INITIAL_DATA_FETCH } from "../graphql/queries";
import { MetaAnswerType } from "../metacognitivas/MetaInterfaces";

export const INDEX_INITIAL_STATUS: ExerciseIndexState = {
  //este es el arbol de tareas que se muestra en LinksScreen
  tareas: [],

  //ejercicios que aun no han sido descargados
  pendingDownloadExercises: [],

  //ejercicios que aun no han sido reportados
  pendingUpload: {},

  preguntasMetacognitivasIndex: [],

  //a flag that says if there are exercises pending to sync or not
  exercisesPendingSync: true,

  //banderas que este usuario ha obtenido
  flags: {
    flagsYear: {},
    flagsWeek: {},
    flagsMonth: {},
  },

  loading: true,

  //indica desde donde obtuvimos la lista de indices.
  //Si estamos online esto transicionará desde UNKNOWN -> LOCAL -> REMOTE
  //en caso de estar offline: UNKNOWN -> LOCAL
  exercisesSource: "UNKNOWN",
  lastUpdateFromConectaIdeas: Date.now(),

  //este es el estado del ejercicio que esta cargado en pantalla
  // exerciseStatus: EXERCISE_INITIAL_STATUS,

  //current screen
  screen: { screen: "Tareas" },

  usuarioId: 0,
  cursoId: 0,
  pais: "unknown",
};

type NextToUploadType = {
  key: string;
  value: GenericUploadType;
} | null;

function getNextExerciseToUpload(
  pendingUpload: PendingUploadCollectionType
): NextToUploadType {
  if (_.isEmpty(pendingUpload)) {
    return null;
  }
  const completed = _.chain(pendingUpload)
    .toPairs()
    .filter((pair) => {
      if (pair[1].completed) {
        return true;
      }
    })
    .head()
    .value();
  if (completed) {
    return {
      key: completed[0],
      value: completed[1],
    } as NextToUploadType;
  } else {
    return null;
  }
}

export default function useExerciseIndex() {
  const [exerciseIndex, exerciseIndexDispatch] = useReducer<
    React.Reducer<ExerciseIndexState, ExerciseIndexAction>
  >(reducerExerciseIndex, INDEX_INITIAL_STATUS);
  const isOnline = useConnectionStatus();
  const client = useApolloClient();
  const conectaIdeasClient = useMemo(() => {
    return new ConectaIdeasClient();
  }, []);
  const [announcementData, setAnnouncementData] = useState<string>();
  const { refreshPreguntasIndex, ready } = {
    refreshPreguntasIndex: () => {},
    ready: false,
  };
  const { loading, error, data, refetch } = useQueryWithOfflineSupport(
    INITIAL_DATA_FETCH,
    {
      pollInterval: 60 * 1000 * 10, //refresh data every 10 minutes
      fetchPolicy: "cache-first",
    }
  );

  const saveAnswer = (
    preguntaId: number,
    answer: MetaAnswerType,
    completed: boolean
  ): Promise<boolean> => {
    const payload = { preguntaId, completed, answer };
    const key = metaAnswerUserInfoStorageKey(preguntaId);
    return AsyncStorage.setItem(key, JSON.stringify({ payload })).then(() => {
      exerciseIndexDispatch({
        type: ActionExerciseIndexType.SEND_META_ANSWER,
        payload: {
          type: "metaAnswer",
          ...payload,
        },
      });
      return true;
    });
  };

  useEffect(() => {
    const nextExerciseToDownload = exerciseIndex.pendingDownloadExercises[0];
    if (nextExerciseToDownload) {
      conectaIdeasClient
        .getCx8File(
          nextExerciseToDownload.id,
          nextExerciseToDownload.tarea_asignada_id,
          nextExerciseToDownload.updated_at,
          nextExerciseToDownload.key
        )
        .then((exercise) => {
          if (exercise) {
            AsyncStorage.setItem(
              exerciseLocalStorageKey(
                nextExerciseToDownload.id,
                nextExerciseToDownload.tarea_asignada_id
              ),
              nextExerciseToDownload.updated_at
            ).then(() => {
              exerciseIndexDispatch({
                type: ActionExerciseIndexType.EXERCISE_DOWNLOADED,
                tareaAsignadaId: nextExerciseToDownload.tarea_asignada_id,
                exerciseId: nextExerciseToDownload.id,
              });
            });
          } else {
            console.error("error downloading", nextExerciseToDownload.id);
          }
        });
    }
  }, [exerciseIndex.pendingDownloadExercises]);

  useEffect(() => {
    const nextExerciseToUpload = getNextExerciseToUpload(
      exerciseIndex.pendingUpload
    );

    if (!isOnline) {
      //no estamos en linea, nada que hacer
      return;
    }

    if (nextExerciseToUpload) {
      const key = nextExerciseToUpload.key;
      const data = nextExerciseToUpload.value;
      uploadEvaluation(client, data)
        .then((result) => {
          if (result) {
            return AsyncStorage.mergeItem(
              key,
              JSON.stringify({
                delivered: moment(),
                payload: { delivered: true },
              })
            );
          } else {
            throw new Error("AFF Got an error, need to retry");
          }
        })
        .then(() => {
          exerciseIndexDispatch({
            type: ActionExerciseIndexType.EXERCISE_REPORTED,
            key,
          });
        })
        .catch((error) => {
          console.error("Error sincronizando, se debe reintentar", error);
        });
    }
  }, [exerciseIndex.pendingUpload, isOnline]);

  useEffect(() => {
    if (error) {
      console.error("AFG error", error);
    }
    if (!loading && !error && data?.getActividades) {
      console.log("getUsuario", data.getUsuario);
      const dataAlumno = data.getUsuario.alumnoCurso;
      const pais = data.getUsuario.pais;
      if (!dataAlumno) {
        console.error("Usuario sin curso asignado");
        exerciseIndexDispatch({
          type: ActionExerciseIndexType.RELOAD_EXERCISE_INDEX,
          exerciseIndex: [],
          dataAlumno: {
            usuarioId: data.getUsuario.id,
            cursoId: 0,
            pais,
          },
          pendingDownloadExercises: [],
          pendingUpload: {},
          flags: {
            flagsYear: {},
            flagsMonth: {},
            flagsWeek: {},
          },
          preguntasMetacognitivasIndex: [],
        });
        return;
      }
      const flags = extractFlags(dataAlumno);

      const preguntasMetacognitivasIndex = [];

      // const preguntasMetacognitivasIndex = getMetacognitivasWithStatus(
      //   dataAlumno.preguntasMetacognitivasPendientes?.items
      // );

      if (data.getMensaje?.text) {
        setAnnouncementData(data.getMensaje.text);
      }
      // console.log("DATA", JSON.stringify(data));
      // console.log("KEYS", Object.keys(data));
      console.log("MENSAJE", JSON.stringify(data.getMensaje));

      getUserStatusForTareas(data.getActividades.tareas)
        .then((indexWithStatus) => {
          const pendingDownloadExercises =
            listPendingDownloadExercises(indexWithStatus);
          const pendingUploadExercises = listPendingUploadExercises();

          return Promise.all([
            pendingUploadExercises,
            Promise.resolve(pendingDownloadExercises),
            Promise.resolve(indexWithStatus),
          ]);
        })
        .then((promises) => {
          const [
            pendingUploadExercises,
            pendingDownloadExercises,
            indexWithStatus,
          ] = promises;
          exerciseIndexDispatch({
            type: ActionExerciseIndexType.RELOAD_EXERCISE_INDEX,
            exerciseIndex: indexWithStatus,
            dataAlumno: {
              usuarioId: dataAlumno.usuario_id,
              cursoId: dataAlumno.curso_id,
              pais,
            },
            pendingDownloadExercises,
            pendingUpload: pendingUploadExercises,
            flags,
            preguntasMetacognitivasIndex,
          });
        });
    }
  }, [loading, data, error]);

  const getExercise = async (
    exerciseId: number,
    tareaAsignadaId: number
  ): Promise<ExerciseWithStatus> => {
    const tareaAsignada = _.find(exerciseIndex.tareas, {
      tarea_asignada_id: tareaAsignadaId,
    });
    if (tareaAsignada) {
      return _.find(tareaAsignada.ejercicios, {
        id: exerciseId,
      });
    }
    if (isDEV && tareaAsignadaId === TAREA_ID_TEST_MODE) {
      //Test mode return the exercise even if it doesn't exist
      return client
        .query({
          query: getExerciseKeyById,
          variables: { exerciseId },
        })
        .then((result) => {
          return {
            ...EXERCISE_INITIAL_STATUS,
            availableOffline: false,
            id: exerciseId,
            key: result.data.getExerciseKey,
            name: `Test Mode: Exercise key ${exerciseId}`,
            position: 1,
            tarea_asignada_id: tareaAsignadaId,
            updated_at: "2019-10-01T17:45:00.627-03:00",
          };
        });
    }
    throw new Error(
      `Error ejercicio desconocido ${exerciseId}, ${tareaAsignadaId}`
    );
  };

  /**
   * Esta es una función que trata de recargar el índice de forma asíncrona desde conectaideas
   * en caso de ser invocada exitosamente se llamará a exerciseIndexDispatch con el nuevo índice
   * si hay error de conectividad a internet entonces no se hace nada
   * @returns nothing
   */
  const refreshIndex = () => {
    Logger.info("refresh everything");
    return refetch();
  };

  return {
    exerciseIndex,
    exerciseIndexDispatch,
    getExercise,
    refreshIndex,
    preguntasIndex: exerciseIndex.preguntasMetacognitivasIndex,
    refreshPreguntasIndex,
    saveAnswer,
    announcementData,
    setAnnouncementData,
    ready,
    isOnline,
  };
}
