import {
  DataAlumnoType,
  ExerciseGraphQL,
  PendingUploadCollectionType,
  ExerciseProgressUploadType,
  FlagsInfoType,
  TareaWithStatus,
  MetaAnswerUploadType,
} from "../Interfaces";
import { MetacognitivasWithStatus } from "../metacognitivas/MetaInterfaces";

export enum ActionExerciseIndexType {
  RELOAD_EXERCISE_INDEX = "RELOAD_EXERCISE_INDEX",
  READY = "READY",
  SET_FLAGS = "SET_FLAGS",
  FINISH_EXERCISE = "FINISH_EXERCISE",
  EXERCISE_DOWNLOADED = "EXERCISE_DOWNLOADED",
  MOVE_TO_EXERCISE_LIST = "MOVE_TO_EXERCISE_LIST",

  //necesitamos sincronizar el estado de un ejercicio en producción.
  SEND_PROGRESS = "SEND_PROGRESS",
  SEND_META_ANSWER = "SEND_META_ANSWER",

  EXERCISE_REPORTED = "EXERCISE_REPORTED",
}

type MoveExerciseListAction = {
  type: ActionExerciseIndexType.MOVE_TO_EXERCISE_LIST;
};

type ReloadIndexAction = {
  type: ActionExerciseIndexType.RELOAD_EXERCISE_INDEX;
  exerciseIndex: TareaWithStatus[];
  pendingDownloadExercises: ExerciseGraphQL[];
  pendingUpload: PendingUploadCollectionType;
  flags: FlagsInfoType;
  dataAlumno: DataAlumnoType;
  preguntasMetacognitivasIndex: MetacognitivasWithStatus[];
  source?: "UNKNOWN" | "LOCAL" | "REMOTE";
};
type SetFlagsAction = {
  type: ActionExerciseIndexType.SET_FLAGS;
  flags: FlagsInfoType;
};
type ExerciseDownloadedAction = {
  type: ActionExerciseIndexType.EXERCISE_DOWNLOADED;
  tareaAsignadaId: number;
  exerciseId: number;
};

type SendProgressAction = {
  type: ActionExerciseIndexType.SEND_PROGRESS;
  payload: ExerciseProgressUploadType;
};

type ExerciseReportedAction = {
  type: ActionExerciseIndexType.EXERCISE_REPORTED;
  key: string;
};

type SaveProgressAction = {
  type: ActionExerciseIndexType.SEND_META_ANSWER;
  payload: MetaAnswerUploadType;
};

export type ExerciseIndexAction =
  | MoveExerciseListAction
  | ReloadIndexAction
  | SetFlagsAction
  | ExerciseDownloadedAction
  | SendProgressAction
  | SaveProgressAction
  | ExerciseReportedAction;
