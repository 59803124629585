import AsyncStorage from "@react-native-async-storage/async-storage";
import * as FileSystem from "expo-file-system";
import _ from "lodash";
import { Platform } from "react-native";

import { getExerciseLocalPath } from "./ExerciseStorage";
import { Logger } from "./Logger";
import { exerciseLocalStorageKey } from "./Utils";

const TAREA_ASIGNADA_ID_INDEX = 2;
const EXERCISE_INDEX = 1;

export async function clearLocalStorage() {
  let storageKeys = await AsyncStorage.getAllKeys();
  try {
    const cachedFiles = _.filter(storageKeys, (key) => {
      return key.startsWith("EXERCISE#");
    });
    if (Platform.OS !== "web") {
      for (const file of cachedFiles) {
        const splitFile = file.split("#");
        const exerciseId = _.parseInt(splitFile[EXERCISE_INDEX]);
        const tareaAsignada = _.parseInt(splitFile[TAREA_ASIGNADA_ID_INDEX]);
        await removeExerciseFromCache(exerciseId, tareaAsignada);
      }
      storageKeys = await AsyncStorage.getAllKeys();
    }
  } catch (error) {
    Logger.captureException("error removing exercises", error);
  }

  try {
    await AsyncStorage.clear();
    storageKeys = await AsyncStorage.getAllKeys();
  } catch (e) {
    Logger.captureException("Error clearing async storage", e);
  }

  if (storageKeys && storageKeys.length > 0) {
    try {
      await AsyncStorage.multiRemove(storageKeys);
    } catch (e) {
      Logger.captureException(
        "Error all cleaning storageKeys " + JSON.stringify(storageKeys),
        e
      );
    }
  }
}

export async function removeExerciseFromCache(
  exerciseId: number,
  tareaAsignadaId: number
) {
  if (Platform.OS !== "web") {
    const localPath = getExerciseLocalPath(exerciseId, tareaAsignadaId);
    try {
      Logger.info("Removing file from cache", localPath);
      const metadata = await FileSystem.getInfoAsync(localPath);
      if (metadata.exists) {
        try {
          await FileSystem.deleteAsync(localPath);
          Logger.info("File deleted successfully", localPath);
        } catch (ignored) {
          //Archivo no encontrado, ignora error
        }
      } else {
        Logger.info("File doesn't exist", localPath);
      }
      await AsyncStorage.removeItem(
        exerciseLocalStorageKey(exerciseId, tareaAsignadaId)
      );
    } catch (e) {
      Logger.info(`Error removing exercise ${exerciseId}`, e);
    }
  }
  return true;
}
