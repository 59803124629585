export const exerciseLocalStorageKey = (
  exerciseId: number,
  tareaAsignadaId: number
) => {
  return `EXERCISE:${exerciseId}:${tareaAsignadaId}`;
};

export const USER_INFO_PREFIX = "USER_v5:";

export function exerciseUserInfoStorageKey(
  exerciseId: number,
  tareaAsignadaId: number,
  position: number
): string {
  return `${USER_INFO_PREFIX}EX:${tareaAsignadaId}:${exerciseId}:${position}`;
}

export function metaAnswerUserInfoStorageKey(preguntaId: number): string {
  return `${USER_INFO_PREFIX}META:${preguntaId}`;
}
